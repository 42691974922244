import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import Layout from '../layout/index';
import SEO from '../components/SEO';
import PostShortList from '../components/postShortList';
import './categories.scss';
import { HeaderMedium } from '../components/headers';

const Categories = ({ data }) => {
  const { group } = data.allMarkdownRemark;
  const categoryList = [];
  group.forEach(category => {
    const posts = [];
    category.edges.forEach(({ node }) => {
      posts.push({
        date: node.frontmatter.date,
        timeToRead: node.timeToRead,
        slug: node.fields.slug,
        title: node.frontmatter.title,
        cover: node.frontmatter.cover,
      });
    });
    categoryList.push({
      fieldValue: category.fieldValue,
      posts,
    });
  });
  return (
    <Layout>
      <SEO title="Categories" path="/categories" />
      <HeaderMedium title="Categories" />
      <ul className="nav justify-content-center">
        {group.map(category => (
          <li className="nav-item" key={category.fieldValue}>
            <button
              type="button"
              className="btn m-2 border-top-0 border-bottom border-left-0 border-right-0 rounded-0 btn-outline-primary"
            >
              <Link className="badge text-dark" to={`/categories/${_.kebabCase(category.fieldValue)}`}>
                {`${category.fieldValue}`}
                <span className="sr-only">{`${category.fieldValue}`} </span>
              </Link>
            </button>
          </li>
        ))}
      </ul>
      <PostShortList data={categoryList} />
    </Layout>
  );
};

Categories.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
          edges: PropTypes.arrayOf(
            PropTypes.shape({
              node: PropTypes.shape({
                fields: PropTypes.shape({
                  slug: PropTypes.string.isRequired,
                }).isRequired,
                timeToRead: PropTypes.number.isRequired,
                frontmatter: PropTypes.shape({
                  title: PropTypes.string.isRequired,
                  cover: PropTypes.object,
                  date: PropTypes.string,
                }).isRequired,
              }).isRequired,
            }),
          ),
        }).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export default Categories;

export const pageQuery = graphql`
  query CategoriesQuery {
    allMarkdownRemark(limit: 2000, sort: { fields: [frontmatter___date], order: DESC }) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        edges {
          node {
            fields {
              slug
            }
            timeToRead
            frontmatter {
              title
              date(formatString: "MMMM DD YYYY")
              cover {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
